import { getDateToday } from "@helpers/date";
import {
  getDomainBySiteCode,
  getLocaleInUrlBySiteCodeAndLocale,
  getPathname,
  getSiteCodes,
  getSiteNameBySiteCode,
} from "@helpers/site";
import { NextRouter } from "next/router";

const SEO_PAGES = [
  "homepage",
  "category",
  "about",
  "contact-us",
  "faq",
  "how-to-earn",
  "popular-stores",
  "privacy-policy",
  "terms",
  "store",
  "deals",
  "refer",
  "coupons",
];

const referralDefaultAmount = {
  amount: "*",
  currencyCode: "",
};

export const getFeaturedCashbackMaxValue = (
  featuredCashback: StoreCashback
): string => {
  if (!featuredCashback) return;

  const featuredCashbackMaxValue = featuredCashback.value.max;
  return featuredCashback.cashbackType === "percentage"
    ? `${featuredCashbackMaxValue}%`
    : `${
        featuredCashback.value.currencyCode
      } ${featuredCashbackMaxValue.toFixed(2)}`;
};

export const getTitle = (
  page: string,
  translate: Translate,
  siteConfig: SiteConfig,
  name?: string,
  featuredCashbackMaxValue?: string,
  dealCount?: number
): string => {
  switch (page) {
    case "homepage":
      return translate("seo_home_meta_title", [siteConfig.domain]);
      break;
    case "about":
      return translate("seo_about_meta_title", [siteConfig.domain]);
      break;
    case "contact-us":
      return translate("seo_contact_meta_title", [siteConfig.domain]);
      break;
    case "faq":
      return translate("seo_faq_meta_title", [siteConfig.domain]);
      break;
    case "how-to-earn":
      return translate("seo_earn_meta_title", [siteConfig.domain]);
      break;
    case "privacy-policy":
      return translate("seo_privacy_meta_title", [siteConfig.domain]);
      break;
    case "terms":
      return translate("seo_terms_meta_title", [siteConfig.domain]);
      break;
    case "popular-stores":
      return translate("seo_popular_stores_meta_title", [
        getDateToday("fullYear"),
        siteConfig.domain,
      ]);
      break;
    case "category":
      return translate("seo_category_meta_title", [
        name,
        getDateToday("fullMonth", siteConfig.locale),
        getDateToday("fullYear"),
        siteConfig.domain,
      ]);
      break;
    case "store":
      return translate("seo_store_name", [
        featuredCashbackMaxValue,
        name,
        getDateToday("fullMonth", siteConfig.locale),
        getDateToday("fullYear"),
        siteConfig.domain,
      ]);
      break;
    case "store-with-cashback":
      return translate("seo_store_name_with_cashback", [
        featuredCashbackMaxValue,
        name,
        getDateToday("fullMonth", siteConfig.locale),
        getDateToday("fullYear"),
        siteConfig.domain,
      ]);
      break;
    case "deals":
      // hardcoding number of deals and cashback rate for now
      return translate("seo_deals_meta_title", [
        "300",
        "30%",
        getDateToday("fullMonth", siteConfig.locale),
        getDateToday("fullYear"),
        siteConfig.domain,
      ]);
    case "refer": {
      const referralPayout =
        siteConfig?.referrals?.referralPayout || referralDefaultAmount;

      const referBonusText = `${referralPayout.currencyCode} ${referralPayout.amount}`;

      return translate("seo_refer_meta_title", [referBonusText]);
    }

    case "coupons": {
      return translate("seo_coupons_meta_title", [name, dealCount?.toString()]);
    }
    case "event": {
      // todo: update with actual page title
      return "Event";
    }
    default:
      break;
  }
  return "";
};

export const getDescription = (
  page: string,
  translate: Translate,
  siteConfig: SiteConfig,
  name?: string,
  featuredCashbackMaxValue?: string
): string => {
  switch (page) {
    case "homepage":
      return translate("seo_home_meta_description", [
        siteConfig.domain,
        getDateToday("fullYear"),
      ]);
      break;
    case "about":
      return translate("seo_about_meta_description");
      break;
    case "contact-us":
      return translate("seo_contact_meta_description");
      break;
    case "faq":
      return translate("seo_faq_meta_description");
      break;
    case "how-to-earn":
      return translate("seo_earn_meta_description");
      break;
    case "privacy-policy":
      return translate("seo_privacy_meta_description");
      break;
    case "terms":
      return translate("seo_terms_meta_description");
      break;
    case "popular-stores":
      return translate("seo_popular_stores_meta_description", [
        getDateToday("fullYear"),
      ]);
      break;
    case "category":
      return translate("seo_category_meta_description", [
        getDateToday("fullMonth", siteConfig.locale),
        getDateToday("fullYear"),
        name,
      ]);
      break;
    case "store":
    case "store-with-cashback":
      return translate("seo_store_meta_description", [
        getDateToday("fullMonth", siteConfig.locale),
        getDateToday("fullYear"),
        name,
        featuredCashbackMaxValue,
      ]);
      break;
    case "deals":
      // hardcoding number of number of stores for now
      return translate("seo_deals_meta_description", [
        siteConfig.domain,
        getDateToday("fullMonth", siteConfig.locale),
        getDateToday("fullYear"),
        "500",
      ]);
    case "refer":
      return translate("seo_refer_meta_description");
    case "coupons":
      return translate("seo_coupons_meta_description", [name]);
    case "event":
      // todo: update with actual page description
      return "Event";
    default:
      break;
  }
  return "";
};

export const getCanonicalHref = (
  router: NextRouter,
  siteConfig: SiteConfig,
  page: string
): string => {
  if (!SEO_PAGES.includes(page)) return;

  const { protocol, domain, siteCode, locale } = siteConfig;
  return (
    protocol +
    domain +
    getLocaleInUrlBySiteCodeAndLocale(siteCode, locale) +
    getPathname(router, page)
  );
};

export declare type HrefLangs = { hrefLang: string; href: string }[];

export const getHrefLangs = (
  router: NextRouter,
  siteConfig: SiteConfig,
  page: string
): HrefLangs => {
  if (
    !SEO_PAGES.includes(page) ||
    ["store", "store-with-cashback"].includes(page)
  )
    return [];

  const { protocol, env, locales } = siteConfig;
  const hrefLangs = [];

  getSiteCodes().map((siteCode) => {
    locales.map((locale) => {
      if (siteCode !== "US") {
        hrefLangs.push({
          hrefLang: `${locale}-${siteCode.toLowerCase()}`,
          href:
            protocol +
            getDomainBySiteCode(env, siteCode) +
            getLocaleInUrlBySiteCodeAndLocale(siteCode, locale) +
            getPathname(router, page),
        });
      }
    });
  });

  hrefLangs.push({
    hrefLang: "x-default",
    href: protocol + getDomainBySiteCode(env, "US") + getPathname(router, page),
  });

  return hrefLangs;
};

interface SiteNameSchema {
  "@context": string;
  "@type": string;
  name: string;
  alternateName: Array<string>;
  url: string;
}

export const getSiteNameSchema = (
  env: string,
  siteCode: string
): SiteNameSchema => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: getSiteNameBySiteCode(siteCode),
    alternateName: ["ShopCash", getDomainBySiteCode(env, siteCode)],
    url: `https://${getDomainBySiteCode(env, siteCode)}/`,
  };
};
