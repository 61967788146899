import { StoreDetails } from "@shopcashTypes/storeDetails";

export enum PageViewBaseTypes {
  home = "home",
  categories = "categories",
  account = "account",
  store = "store",
  wallet = "wallet",
  handoff = "handoff",
  deals = "deals",
  referral = "referral",
  withdrawBank = "withdraw_bank",
  coupons = "coupons",
  withdrawGiftCard = "withdraw_giftcard",
  extension = "extension",
  events = "events",
}

const basePage = () => ({
  url: document.URL,
  referrerUrl: document.referrer,
});

export const getHomePageView = (): GenzoPage => ({
  ...basePage(),
  name: "home",
  baseType: PageViewBaseTypes.home,
  subType: "home",
});

export const getCategoryPageView = (category: Category): GenzoPage => ({
  ...basePage(),
  name: category.enName,
  baseType: PageViewBaseTypes.categories,
  subType: "categories",
});

export const getStoreDetailsPageView = (store: StoreDetails): GenzoPage => ({
  ...basePage(),
  name: store.enName,
  baseType: PageViewBaseTypes.store,
  subType: "store",
});

export const getPopularStorePageView = (): GenzoPage => ({
  ...basePage(),
  name: "Store - Popular Stores",
  baseType: PageViewBaseTypes.store,
  subType: "popular-stores",
});

export const getWalletPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Wallet",
  baseType: PageViewBaseTypes.wallet,
  subType: "wallet",
});

export const getCashbackHistoryPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Wallet - Cashback History",
  baseType: PageViewBaseTypes.wallet,
  subType: "wallet_cashback-history",
});

export const getClickHistoryPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Wallet - Click History",
  baseType: PageViewBaseTypes.wallet,
  subType: "wallet_click-history",
});

export const getWithdrawPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Wallet - Withdraw Cashback",
  baseType: PageViewBaseTypes.wallet,
  subType: "wallet_withdraw",
});

export const getWalletReferralPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Wallet - Referral",
  baseType: PageViewBaseTypes.wallet,
  subType: "wallet_referral",
});

export const getAccountPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account",
  baseType: PageViewBaseTypes.account,
  subType: "account",
});

export const getFavouriteStoresPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account - favourites",
  baseType: PageViewBaseTypes.account,
  subType: "favourites",
});

export const getNotificationsPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account - notifications",
  baseType: PageViewBaseTypes.account,
  subType: "notifications",
});

export const getFaqPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account - Faq",
  baseType: PageViewBaseTypes.account,
  subType: "faq",
});

export const getContactPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account - Contact",
  baseType: PageViewBaseTypes.account,
  subType: "contact",
});

export const getPrivacyPolicyPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account - Privacy",
  baseType: PageViewBaseTypes.account,
  subType: "privacy",
});

export const getAboutPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account - About",
  baseType: PageViewBaseTypes.account,
  subType: "about",
});

export const getTermsPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Account - Terms",
  baseType: PageViewBaseTypes.account,
  subType: "terms",
});

export const getHowToEarnPageView = (): GenzoPage => ({
  ...basePage(),
  name: "How to Earn",
  baseType: PageViewBaseTypes.account,
  subType: "how-to-earn",
});

export const getHandoffPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Handoff",
  baseType: PageViewBaseTypes.handoff,
  subType: "handoff",
});

export const getDealsPageView = (): GenzoPage => ({
  ...basePage(),
  name: "All Deals",
  baseType: PageViewBaseTypes.deals,
  subType: "all_deals",
});

export const getReferAndEarnPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Referral",
  baseType: PageViewBaseTypes.referral,
  subType: "referral",
});

export const getAddBankFormPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - Add Bank Account",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "add_bank",
});

export const getAddBankFormSuccessView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - Bank Account Added",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "add_bank_success",
});

export const getEditBankFormPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - Edit Bank Form",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "edit_bank_form",
});

export const getEditBankFormSuccessView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - Bank Account Updated",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "edit_bank_success",
});

export const getDeletedBankView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - Bank Account Deleted",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "deleted_bank",
});

export const getBankTransferPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - Overview",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "withdraw_bank",
});

export const getAddEmailBankView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - Add Email",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "add_email",
});

export const getAddBankOtpView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - OTP",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "otp_add",
});

export const getUpdateBankOtpView = (): GenzoPage => ({
  ...basePage(),
  name: "Withdraw as Bank - OTP - Update Bank",
  baseType: PageViewBaseTypes.withdrawBank,
  subType: "otp_update",
});

export const getEventPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Events",
  baseType: PageViewBaseTypes.events,
  subType: "events",
});

const withdrawGiftCardBasePage = (
  giftCardId: string,
  giftCardValueId?: string
) => ({
  url: `${document.URL}/${giftCardId}/${giftCardValueId ?? ""}`,
  referrerUrl: document.referrer,
});

export const getGiftCardPageView = (
  giftCardId: string,
  merchantName: string
): GenzoPage => ({
  ...withdrawGiftCardBasePage(giftCardId),
  name: `Withdraw via Gift Card - Select Amount - ${merchantName}`,
  baseType: PageViewBaseTypes.withdrawGiftCard,
  subType: "select_amount",
});

export const getConfirmWithdrawalGiftCardView = (
  giftCardId: string,
  giftCardValueId: string,
  merchantName: string
): GenzoPage => ({
  ...withdrawGiftCardBasePage(giftCardId, giftCardValueId),
  name: `Withdraw via Gift Card - Confirm Withdrawal - ${merchantName}`,
  baseType: PageViewBaseTypes.withdrawGiftCard,
  subType: "confirm_withdrawal",
});

export const getProfileUpdateView = (
  giftCardId: string,
  giftCardValueId: string
): GenzoPage => ({
  ...withdrawGiftCardBasePage(giftCardId, giftCardValueId),
  name: `Withdraw via Gift Card - Profile Update`,
  baseType: PageViewBaseTypes.withdrawGiftCard,
  subType: "profile_update",
});

export const getOtpGiftCardView = (
  giftCardId: string,
  giftCardValueId: string,
  merchantName: string
): GenzoPage => ({
  ...withdrawGiftCardBasePage(giftCardId, giftCardValueId),
  name: `Withdraw via Gift Card - OTP - ${merchantName}`,
  baseType: PageViewBaseTypes.withdrawGiftCard,
  subType: "otp_withdrawal",
});

export const getConfirmationGiftCardView = (
  giftCardId: string,
  giftCardValueId: string,
  merchantName: string
): GenzoPage => ({
  ...withdrawGiftCardBasePage(giftCardId, giftCardValueId),
  name: `Withdraw via Gift Card - Withdrawal Pending - ${merchantName}`,
  baseType: PageViewBaseTypes.withdrawGiftCard,
  subType: "withdrawal_success",
});

export const getErrorMessageGiftCardView = (
  giftCardId: string,
  giftCardValueId: string,
  merchantName: string
): GenzoPage => ({
  ...withdrawGiftCardBasePage(giftCardId, giftCardValueId),
  name: `Withdraw via Gift Card - Withdrawal Error - ${merchantName}`,
  baseType: PageViewBaseTypes.withdrawGiftCard,
  subType: "withdrawal_error",
});

export const getCouponPageView = (category: Category): GenzoPage => ({
  ...basePage(),
  name: `Coupons -  ${category.enName}`,
  baseType: PageViewBaseTypes.coupons,
  subType: "deals_coupons",
});

export const getExtensionPageFirstInstallView = (): GenzoPage => ({
  ...basePage(),
  name: "Extension - Login - Install",
  baseType: PageViewBaseTypes.extension,
  subType: "install",
});

export const getExtensionPageLoginView = (): GenzoPage => ({
  ...basePage(),
  name: "Extension - Login",
  baseType: PageViewBaseTypes.extension,
  subType: "login",
});

export const getExtensionTipsPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Extension - Tips",
  baseType: PageViewBaseTypes.extension,
  subType: "tips",
});

export const getExtensionUninstallPageView = (): GenzoPage => ({
  ...basePage(),
  name: "Extension - Uninstall",
  baseType: PageViewBaseTypes.extension,
  subType: "uninstall",
});
