import styles from "./DealCouponLoggedInView.module.scss";
import Button from "@components/Button";
import Image from "@components/_shared/NextImage";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import CopyIcon from "@icons/copy.svg";
import CopyWithCheckIcon from "@icons/copyWithCheck.svg";
import { DealCouponModalCtaType } from "@shopcashTypes/dealCouponModalCtaType";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import router from "next/router";
import { useEffect, useState } from "react";

interface DealCouponLoggedInViewProps {
  deal: StoreDeal;
  showViewMore?: boolean;
  isStoreLogoClickable?: boolean;
  onShopNowClick: () => void;
  onEventTrack?: (ctaType: DealCouponModalCtaType) => void;
}

const DealCouponLoggedInView: React.FC<DealCouponLoggedInViewProps> = ({
  deal,
  showViewMore = false,
  isStoreLogoClickable = true,
  onShopNowClick,
  onEventTrack,
}) => {
  const translate = useTranslations();
  const { isRtl } = useSiteConfig();
  const [isCodeCopied, setIsCodeCopied] = useState<boolean>(false);

  useEffect(() => {
    const delayCopyCodeTimer = setTimeout(() => {
      setIsCodeCopied(true);
    }, 500);

    return () => clearTimeout(delayCopyCodeTimer);
  }, [deal]);

  useEffect(() => {
    if (!isCodeCopied) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator?.clipboard?.writeText(deal.couponCode);

    const copyCodeTimer = setTimeout(() => {
      setIsCodeCopied(false);
    }, 3000);

    return () => clearTimeout(copyCodeTimer);
  }, [isCodeCopied]);

  const handleCopyCodeClick = () => {
    setIsCodeCopied(true);
    onEventTrack && onEventTrack(DealCouponModalCtaType.CopyCode);
  };

  const handleViewMoreClick = (deal: StoreDeal) => {
    onEventTrack && onEventTrack(DealCouponModalCtaType.ViewMoreDeals);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({ pathname: `/store/${deal.store.slug}` });
  };

  const handleStoreLogoClick = () => {
    if (!isStoreLogoClickable) return;
    onEventTrack && onEventTrack(DealCouponModalCtaType.StoreLogo);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({ pathname: `/store/${deal.store.slug}` });
  };

  return (
    <div
      className={clsx(styles.container, isRtl && styles.rtl)}
      data-testid="deal-coupon-modal-loggedin"
    >
      <div
        className={clsx(
          styles.storeLogo,
          isStoreLogoClickable && styles.clickable
        )}
        onClick={handleStoreLogoClick}
      >
        <Image
          src={deal.store.logoUrl}
          layout="fill"
          objectFit="contain"
          alt={deal.store.name}
        ></Image>
      </div>
      <div>{deal.store.name}</div>
      <div className={styles.dealName}>{deal.title}</div>

      <div
        className={styles.dealCouponContainer}
        onClick={() => handleCopyCodeClick()}
      >
        <div className={styles.dealCoupon}>{deal.couponCode}</div>

        <div className={styles.codeCopiedText}>{translate("code_copied")}</div>

        <CopyWithCheckIcon
          className={clsx(isCodeCopied && styles.show, styles.copyIcon)}
        />
        <CopyIcon
          className={clsx(!isCodeCopied && styles.show, styles.copyIcon)}
        />
      </div>

      <div className={styles.note}>{translate("dont_forget_to_use")}</div>

      <Button
        variant={VariantButton.Primary}
        onClick={onShopNowClick}
        fullWidth
        large
      >
        {deal.store.featuredCashback
          ? translate("shop_now_and_get", [
              deal.store.cashbackText?.toLowerCase(),
            ])
          : translate("shop_now")}
      </Button>

      {showViewMore && (
        <div
          className={styles.viewMore}
          onClick={() => handleViewMoreClick(deal)}
        >
          {translate("view_more_deals_for", [deal.store.name])}
        </div>
      )}
    </div>
  );
};

export default DealCouponLoggedInView;
