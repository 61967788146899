import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import {
  getCanonicalHref,
  getDescription,
  getHrefLangs,
  getTitle,
  HrefLangs,
} from "@helpers/seo";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface SeoHeadProps {
  page: string;
  name?: string;
  featuredCashbackMaxValue?: string;
  dealCount?: number;
}

const SeoHead: React.FC<SeoHeadProps> = ({
  page,
  name,
  featuredCashbackMaxValue,
  dealCount,
}) => {
  const router = useRouter();
  const siteConfig = useSiteConfig();
  const translate = useTranslations();

  const [title, setTitle] = useState<string>(
    getTitle(
      page,
      translate,
      siteConfig,
      name,
      featuredCashbackMaxValue,
      dealCount
    )
  );
  const [description, setDescription] = useState<string>(
    getDescription(page, translate, siteConfig, name, featuredCashbackMaxValue)
  );
  const [canonicalHref, setCanonicalHref] = useState<string>(
    getCanonicalHref(router, siteConfig, page)
  );

  const [hrefLangs, setHrefLangs] = useState<HrefLangs>(
    getHrefLangs(router, siteConfig, page)
  );

  useEffect(() => {
    setTitle(
      getTitle(
        page,
        translate,
        siteConfig,
        name,
        featuredCashbackMaxValue,
        dealCount
      )
    );
  }, [page, translate, siteConfig, name, featuredCashbackMaxValue, dealCount]);

  useEffect(() => {
    setDescription(
      getDescription(
        page,
        translate,
        siteConfig,
        name,
        featuredCashbackMaxValue
      )
    );
  }, [page, translate, siteConfig, name, featuredCashbackMaxValue]);

  useEffect(() => {
    setCanonicalHref(getCanonicalHref(router, siteConfig, page));
  }, [siteConfig, page]);

  useEffect(() => {
    setHrefLangs(getHrefLangs(router, siteConfig, page));
  }, [siteConfig, page]);

  return (
    <Head>
      {!!title && <title>{title}</title>}

      {!!description && <meta name="description" content={description} />}

      {!!canonicalHref && <link rel="canonical" href={canonicalHref} />}

      {!!hrefLangs.length &&
        hrefLangs.map((hrefLang) => {
          return (
            <link
              key={hrefLang.hrefLang}
              rel="alternate"
              hrefLang={hrefLang.hrefLang}
              href={hrefLang.href}
            />
          );
        })}
      <link rel="apple-touch-icon" href="/shopcash_logo_orange.png" />
      <link rel="icon" href="/shopcash_logo_orange.png" />
    </Head>
  );
};

export default SeoHead;
