import AuthenticationForm from "@components/AuthenticationForm";
import Modal from "@components/_shared/Modal";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import {
  getAuthenticationFormClickEvent,
  getDealCouponModalView,
} from "@helpers/genzo/genzoEventActionsBuilder";
import { getLocaleInUrl } from "@helpers/site";
import CrossIcon from "@icons/cross.svg";
import { DealCollectionType } from "@shopcashTypes/dealCollectionType";
import { DealCouponModalCtaType } from "@shopcashTypes/dealCouponModalCtaType";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import DealCouponLoggedInView from "./DealCouponLoggedInView";
import styles from "./DealCouponModal.module.scss";

interface DealCouponModalProps {
  deal: StoreDeal;
  show: boolean;
  showViewMore?: boolean;
  isStoreLogoClickable?: boolean;
  onCloseClick: () => void;
  onEventTrack?: (ctaType: DealCouponModalCtaType) => void;
  collectionType?: DealCollectionType;
}

const DealCouponModal: React.FC<DealCouponModalProps> = ({
  deal,
  show,
  showViewMore = false,
  isStoreLogoClickable = true,
  onCloseClick,
  onEventTrack,
  collectionType,
}) => {
  const { locale, defaultLocale, isMobile } = useSiteConfig();
  const { user } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { trackEvent } = useGenzoTracking();
  const router = useRouter();

  const handleShopNowClick = () => {
    if (user) {
      const localeInUrl = getLocaleInUrl(defaultLocale, locale);
      const dealHandoffUrl = `${localeInUrl}/handoff/deal/${deal.id}`;

      onEventTrack && onEventTrack(DealCouponModalCtaType.ShopNow);
      window.open(dealHandoffUrl, "_blank");
    } else {
      handleSignInForCodeClick();
    }
  };

  const handleSignInForCodeClick = () => {
    onEventTrack && onEventTrack(DealCouponModalCtaType.SignInForCode);
    setShowAuthModal(true);
  };

  const handleAuthenticationFormOnClose = () => {
    setShowAuthModal(false);
  };

  const handleCloseModalClick = () => {
    setShowAuthModal(false);
    if (onCloseClick) onCloseClick();
  };

  useEffect(() => {
    if (showAuthModal) {
      trackEvent(getAuthenticationFormClickEvent());
    }
  }, [showAuthModal]);

  useEffect(() => {
    if (show && user) {
      const event = getDealCouponModalView(
        router.pathname,
        deal,
        collectionType
      );
      trackEvent(event);
    }
  }, [show, user]);

  return (
    <Modal
      className={clsx(
        styles.modalOverride,
        showAuthModal && isMobile && styles.fullScreen
      )}
      show={show}
    >
      {showAuthModal && !isMobile && (
        <CrossIcon
          onClick={handleCloseModalClick}
          className={styles.closeIcon}
          data-testid="deal-coupon-modal-close"
        ></CrossIcon>
      )}

      {showAuthModal ? (
        <AuthenticationForm onClose={handleAuthenticationFormOnClose} />
      ) : (
        <>
          <CrossIcon
            onClick={handleCloseModalClick}
            className={styles.closeIcon}
            data-testid="deal-coupon-modal-close"
          ></CrossIcon>
          <DealCouponLoggedInView
            deal={deal}
            onShopNowClick={handleShopNowClick}
            onEventTrack={onEventTrack}
            showViewMore={showViewMore}
            isStoreLogoClickable={isStoreLogoClickable}
          />
        </>
      )}
    </Modal>
  );
};

export default DealCouponModal;
